import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalTiles from "../components/globalTiles"
import HomeSlider from "../components/homeSlider"

const IndexPage = () => {
  const lang = "en"

  return (
    <Layout lang={lang} translation="/pl/">
      <SEO title="Home" />

      <section className="home-top" id="trigger">
        <div className="home-top__wrapper global__padd">
          <h2>
            UNITY <br /> <span>Centre</span>
          </h2>
          <h2>Krakow's Business Complex</h2>
        </div>

        <div className="global__padd">
          <HomeSlider lang={lang} />
        </div>

        <div className="home-top__desc global__padd">
          <div className="row">
            <div className="col-md-6">
              <h1>
                UNITY CENTRE - Krakow’s greatest multifunctional business
                complex
              </h1>
            </div>

            <div className="col-md-5 offset-md-1">
              <div className="home-top__inner">
                <p>
                  The story about the UNITY CENTRE complex from 1968 as one of
                  the most recognizable buildings to a bold return as an icon in
                  the heart of Krakow.
                </p>
              </div>
            </div>
          </div>
          <span className="global__line"></span>
        </div>
      </section>

      <GlobalTiles lang={lang} />

      <section className="home-info global__padd">
        <div className="home-info__wrapper">
          <div className="row flex-column-reverse flex-md-row">
            <div className="col-md-6">
              <div className="home-info__inner home-info__inner--left">
                <h2>
                  50 000 <span>SQM</span>
                </h2>
                <h4>of leasable area</h4>
                <div className="home-info__ico">
                  <img
                    src={require("../assets/images/home-info-ico-1.svg")}
                    alt=""
                  />
                  <div>
                    <strong>26</strong>
                    <p>overground floors (UNITY Tower)</p>
                  </div>
                </div>
                <div className="home-info__ico">
                  <img
                    src={require("../assets/images/home-info-ico-2.svg")}
                    alt=""
                  />
                  <div>
                    <strong>24</strong>
                    <p>high-speed lifts in four office buildings</p>
                  </div>
                </div>
                <div className="home-info__ico">
                  <img
                    src={require("../assets/images/home-info-ico-3.svg")}
                    alt=""
                  />
                  <div>
                    <strong>400</strong>
                    <p>underground parking spaces</p>
                  </div>
                </div>
                <div className="home-info__ico">
                  <img
                    src={require("../assets/images/home-info-ico-4.svg")}
                    alt=""
                  />
                  <div>
                    <strong>200</strong>
                    <p>cycle racks</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="home-info__inner home-info__inner--right">
                <h3>
                  UNITY CENTRE - Krakow’s greatest multifunctional business
                  complex
                </h3>
                <p>
                  The full 50 000 SQM mixed-use neighborhood plan will include
                  ample work spaces, service areas, luxury residences, hotel and
                  welcoming public spots.
                </p>
                <Link to="/about/" className="btn-custom btn-custom--arrow">
                  Read more
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
